.gpt3__features-container__feature {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
  }
  .gpt3__features-container__feature-title {
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;
  }
  .gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #ffffff;
  }
  .gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
  
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
  }
  .gpt3__features-container__feature-text {
    flex: 2;
    display: flex;
    max-width: 390px;
  }
  .gpt3__features-container__feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
  }

  .gpt3__features-container__feature-text_1 {
    flex: 2;
    display: flex;
    max-width: 1000px;
  }
  .gpt3__features-container__feature-text_1 p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
  }
  
  @media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
      font-size: 14px;
      line-height: 22px;
    }
  
    .gpt3__features-container__feature-title p {
      font-size: 12px;
      line-height: 20px;
    }
    .gpt3__features-container__feature {
      margin: 1rem 0;
    }
  }
  