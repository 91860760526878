.gpt3__possibility {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #1a1a1a; /* Dark background */
  color: #ffffff; /* White text for contrast */
  margin: 0 auto;
}

.gpt3__possibility-content {
  max-width: 1120px; /* Centering the content */
  text-align: left;
}

.gpt3__possibility-content h1 {
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 1rem;
  color: #71e5ff; /* Gradient text color */
}

.gpt3__possibility-content h4 {
  font-weight: 500;
  font-size: 20px;
  margin: 1rem 0;
  color: #ff8a71; /* Another accent color */
}

.gpt3__possibility-content p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 1rem;
}

ul {
  margin: 0;
  padding: 0 1.5rem;
  list-style-type: disc;
}

@media screen and (max-width: 950px) {
  .gpt3__possibility {
    padding: 1rem; /* Reduce padding on smaller screens */
  }

  .gpt3__possibility-content h1 {
    font-size: 28px;
  }

  .gpt3__possibility-content h4 {
    font-size: 18px;
  }

  .gpt3__possibility-content p {
    font-size: 14px;
  }
}
