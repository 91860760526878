.gpt3__cta_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    padding: 2rem;
    margin: 4rem;
    background: var(--gradient-bar);
    
  }
  .gpt3__cta-content_1 p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
  
    color: #0e0e0e;
  }
  
  .gpt3__cta-content_1 h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 45px;
    text-align: center;
  
    color: #000000;
  }
  .gpt3__cta-btn_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
  }
  .gpt3__cta-btn_1 button {
    background: #000000;
    border-radius: 40px;
    font-family: var(--font-family);
  
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    padding: 0.1rem 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
  }
  
  @media screen and (max-width: 650px) {
    .gpt3__cta_1 {
      flex-direction: column;
    }
    .gpt3__cta-btn_1 {
      margin: 2rem 0 0;
    }
  }
  @media screen and (max-width: 550px) {
    .gpt3__cta_1 {
      margin: 4rem 2rem;
    }
    .gpt3__cta-content_1 h3 {
      font-size: 18px;
      line-height: 32px;
    }
    .gpt3__cta-btn_1 button {
      font-size: 14px;
      line-height: 28px;
    }
  }
  